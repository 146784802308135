.SimpleIncomingMessage {
  max-width: 600px;
  margin: 9px 80px 0px 22px;
  padding: 12px 12px;
  align-self: flex-start;
  border-radius: 15px 15px 15px 0px;
  background-color: white;
  border-color: transparent;
  resize: none;
  overflow-wrap: break-word;
  word-wrap: break-word;
  white-space: pre-wrap;
  line-height: 18px;
}

.ConversationStreamRtl .SimpleIncomingMessage {
  margin: 9px 22px 0px 80px;
  border-radius: 15px 15px 0px 15px;
}
