.markdown-body {
  background-color: transparent;
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: -0.408px;
}
.markdown-body * {
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: -0.408px;
}
.markdown-body pre {
    white-space: pre-wrap;
    word-wrap: break-word;
    background-color: #f8f8f8;
    border: 1px solid #dfdfdf;
    margin-top: 1.5em;
    margin-bottom: 1.5em;
    padding: 0.125rem 0.3125rem 0.0625rem;
}
.markdown-body pre code {
    background-color: transparent;
    white-space: pre-wrap;
    word-wrap: break-word;
}
.markdown-body .LinkInsideMessage {
  /*color: #a8ddff;*/
  color: #66D9EF;
  font-style: italic;
  text-decoration: underline;
}
