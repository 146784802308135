.ConversationStreamRtl .OutgoingMessage {
  margin: 9px 80px 9px 8px;
}

.ConversationStreamRtl .OutgoingMessage.SIMPLE_TEXT_BLOCK,
.OutgoingMessage.RANDOM_MESSAGE_BLOCK,
.OutgoingMessage.LINK_TEXT_BLOCK {
  border-radius: 15px 15px 15px 0px;
}

.OutgoingMessage {
  margin: 9px 8px 9px 80px;
  max-width: 800px;
  align-self: end;
  color: black;
}

.OutgoingMessage.HTML_BLOCK {
  background-color: transparent;
  border-radius: 15px 15px 0px 15px;
  border-color: transparent;
  resize: none;
  word-wrap: break-word;

  line-height: 18px;
  color: #ffffff;
}

.OutgoingMessage.IMAGE_BLOCK {
  max-width: 383px;
}

.OutgoingMessage.CAROUSEL_BLOCK {
  max-width: 383px;
  align-self: none;
  padding: 8px 0px;
  color: white !important;
}

.OutgoingMessage.AUDIO_MESSAGE,
.OutgoingMessage.DOCUMENT_MESSAGE {
  display: flex;
  flex-direction: column;
  border-radius: 15px 15px 0px 15px;
  padding: 12px 12px;
  background-color: #2a86ff;
  border-color: transparent;
  max-width: 180px;
  resize: none;
  word-wrap: break-word;

  line-height: 18px;

  white-space: pre-wrap;
  color: white !important;
}

.OutgoingMessage.SIMPLE_TEXT_BLOCK,
.OutgoingMessage.RANDOM_MESSAGE_BLOCK,
.OutgoingMessage.LINK_TEXT_BLOCK {
  border-radius: 15px 15px 0px 15px;
  padding: 12px 12px;
  background-color: #2a86ff;
  border-color: transparent;
  max-width: 600px;
  resize: none;
  word-wrap: break-word;
  line-height: 18px;
  color: white !important;
}

.OutgoingMessage.SIMPLE_TEXT_BLOCK .markdown-body {
  color: white !important;
}
.OutgoingMessage.RANDOM_MESSAGE_BLOCK .markdown-body {
  color: white !important;
}
.OutgoingMessage.LINK_TEXT_BLOCK {
  border-radius: 15px 15px 0px 15px;
  background-color: white;
  border-color: transparent;
  box-shadow: 0px 0px 2px #aaaaaa;
  color: black !important;

  a {
    text-decoration: underline;
  }
}
