.SenderName {
  font-weight: 500 !important;
  line-height: 14px;
  letter-spacing: -0.015em;
  color: #56595f !important;
}

.SenderContainer {
  display: flex;
  align-items: center;
  margin-left: 22px;
}

.ConversationStreamRtl .SenderContainer {
  margin-left: 0;
  margin-right: 22px;
}

.SenderNameIncoming {
  margin-left: 5px;
}

.SenderNameOutgoing {
  margin-right: 8px;
}

.ConversationStreamRtl .SenderNameIncoming {
  margin-right: 5px;
}

.ConversationStreamRtl .SenderNameOutgoing {
  margin-left: 8px;
}
