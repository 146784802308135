.ConversationStream {
  position: relative !important;
  overflow-y: auto;
  z-index: 0;
  height: 100%;

  .Messages {
    display: flex;
    width: 100%;
    flex-direction: column;
    flex: 1;
    overflow: auto;
    background-color: #f4f7fe;
    padding: 15px 15px 15px 0;

    .DateDivider {
      margin: 0 !important;

      .date-divider-label {
        margin: 9px 0;
        padding: 8px 15px 8px 15px;
        resize: none;
        word-wrap: break-word;
        background-color: rgba(42, 134, 255, 0.09);

        border-color: transparent;
        align-items: center;

        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        text-align: center;
        color: #2a86ff;

        border-radius: 10px;
      }
    }
    .timestamp {
      margin-right: 8px;
      margin-left: 22px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      line-height: 18px;
      font-weight: 400;
      color: rgba(86, 89, 95, 0.4);
    }
    .timestamp-end {
      justify-content: flex-end;
    }
    .timestamp-with-translation {
      margin-bottom: 8px;
    }
    .translate-text {
      margin-left: 4px;
      cursor: pointer;
      font-size: 13px;
      line-height: 18px;
      font-weight: 400;
      //color: #56595f;
      color: rgba(86, 89, 95, 0.4);
    }
    .translate-icon {
      margin-left: 5px;
      height: 13px;
      width: 13px;
      fill: rgba(86, 89, 95, 0.4);
    }
    .translate-text:hover {
      text-decoration: underline;
    }
  }

  .LoadMore {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .UserMessage {
    border-radius: 12px;
    border-color: transparent;
    resize: none;
    padding: 16px 24px 16px 24px;
    max-width: 289px;
    word-wrap: break-word;
    font-size: 14px;
    margin: 9px 0;

    align-self: flex-start;
    background-color: #ebebeb;
  }

  .UserMessage * {
    border-left-color: #212529 !important;
  }
}

.ConversationStreamRtl {
  //direction: rtl;
}
.ConversationStreamRtl * {
  direction: rtl;
  text-align: right;

  .Messages {
    padding: 15px 0 15px 15px;
  }
}

.ConversationStreamRtl .Messages {
  padding: 15px 0 15px 15px;

  .timestamp {
    margin-right: 22px;
    margin-left: 8px;
  }
}
